import { api, handleResponse, handleError } from "./apiServices";

export const AddCourseData = (token, data) =>
  api(token)
    .post(`/api/course`, data)
    .then(handleResponse)
    .catch(handleError);

export const EditCourse = (token, id, data) =>
  api(token)
    .put(`/api/course/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const getCourses = (type) =>
  api()
    .get(`/api/course?type=${type}`)
    .then(handleResponse)
    .catch(handleError);
