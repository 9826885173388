import { logoutApi } from "services/authServices";
import { toast } from "react-toastify";

const authActions = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  SET_USER: "SET_USER",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",

  login: (data) => {
    localStorage.setItem("isLogin", true);
    localStorage.setItem("accessToken", data?.token);
    return {
      type: authActions.LOGIN,
      isLogin: true,
      accessToken: data,
    };
  },
  setUser: (data) => {
    return {
      type: authActions.SET_USER,
      user: data,
    };
  },
  check: (data) => {
    localStorage.setItem("isLogin", true);
    localStorage.setItem("accessToken", data.token);
    return {
      type: authActions.LOGIN,
      isLogin: true,
      accessToken: data.token,
      user: data,
    };
  },
  logout: (token) => {
    if (token !== null) {
      logoutApi(token).then((data) => {});
    }
    localStorage.setItem("isLogin", false);
    localStorage.setItem("accessToken", null);
    document.cookie = document.cookie = `token= ;SameSite=strict;max-age=0}`;
    return {
      type: authActions.LOGOUT,
      isLogin: false,
      accessToken: null,
    };
  },
  success: (messages) => {
    messages !== "" &&
      toast.success(messages, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    return {
      type: authActions.SUCCESS,
      resType: "success",
      messages: messages,
      show: true,
      isFetching: false,
    };
  },

  error: (messages) => {
    messages !== "" &&
      toast.error(messages, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    return {
      type: authActions.ERROR,
      resType: "error",
      messages: messages,
      show: true,
      isFetching: false,
    };
  },
};

export default authActions;
