import { api, handleResponse, handleError } from "./apiServices";

export const getAllEnquiry = (token, data) =>
  api(token)
    .get(
      `/api/course?page=${data.currentPage}&limit=${data.limit}&search=${data.search}`
    )
    .then(handleResponse);

export const EditEnrollment = (token, id, data) =>
  api(token)
    .put(`/api/student-enrollment/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const addEnrollment = (data) =>
  api()
    .post(`/api/student-enrollment`, data)
    .then(handleResponse)
    .catch(handleError);

export const deleteCourse = (token, id, data) =>
  api(token)
    .delete(`/api/course/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const getStudentEnrollmentEnquiry = (token, data) =>
  api(token)
    .get(
      `/api/student-enrollment?page=${data.currentPage}&limit=${data.limit}&search=${data.search}`
    )
    .then(handleResponse)
    .catch(handleError);

export const paymentAlert = (token, id) =>
  api(token)
    .put(`/api/student-enrollment-payment-alert/${id}`)
    .then(handleResponse)
    .catch(handleError);

export const paymentFeeDownload = (token, id) =>
  api(token)
    .get(`/api/student-enrollment-fee-download/${id}`)
    .then(handleResponse)
    .catch(handleError);

export const enrollmentStatusUpdate = (token, id) =>
  api(token)
    .put(`/api/student-enrollment-status/${id}`)
    .then(handleResponse)
    .catch(handleError);

export const deleteStudentEnrollment = (token, id) =>
  api(token)
    .delete(`/api/student-enrollment/${id}`)
    .then(handleResponse)
    .catch(handleError);

export const editPaymentData = (token, data, id, paymentId) =>
  api(token)
    .put(`/api/student-enrollment-payment/${id}/${paymentId}`, data)
    .then(handleResponse)
    .catch(handleError);

export const addAnnouncementData = (token, data, id) =>
  api(token)
    .put(`/api/student-enrollment-annocument/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const addBulkAnnouncementData = (token, data) =>
  api(token)
    .put(`/api/bulk-student-enrollment-annocument`, data)
    .then(handleResponse)
    .catch(handleError);

export const deleteStudentEnrollmentAnnouncement = (token, id, annocumentId) =>
  api(token)
    .delete(`/api/student-enrollment-annocument/${id}/${annocumentId}`)
    .then(handleResponse)
    .catch(handleError);

export const sentEmailStudentEnrollmentAnnouncement = (
  token,
  id,
  annocumentId
) =>
  api(token)
    .put(`/api/student-enrollment-annocument-resent/${id}/${annocumentId}`)
    .then(handleResponse)
    .catch(handleError);
