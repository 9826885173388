export const AppName = "Webbrain tech";
export const drawerWidth = "260px";
export const miniDrawerWidth = "80px";
export const themeSettingDrawerWidth = "300px";
export const chatDrawerWidth = "260px";
export const chatMiniDrawerWidth = "0px";

export const loginBack = require("assets/images/loginback.jpg");
export const iconDemo = require("assets/images/webtech-logo.png");
export const locakscreenBack = require("assets/images/lockscreen.jpg");
export const ProfileLockScreen = require("assets/images/profile.jpg");
export const sidebar1 = require("assets/images/sidebar1.jpg");
export const webrainTechLogo = require("assets/images/webtech-logo.png");
export const webrainTechMiniLogo = require("assets/images/webtech-logo.png");
