import axios from "axios";
import NavigationActions from "redux/navigation/actions";

const {
  success,
  error,
  toggleOneTimeModal,
  fetching,
  toggleSubscriptionLoader,
} = NavigationActions;

const BACKEND_URI = process.env.REACT_APP_BACKEND_URI
  ? process.env.REACT_APP_BACKEND_URI
  : "";

export const api = (token) => {
  try {
    if (typeof token === "string" && token.split(".").length === 3)
      return axios.create({
        // withCredentials: true,
        baseURL: `${BACKEND_URI}/`,
        headers: { Authorization: `Bearer ${token}` },
      });
    else
      return axios.create({
        // withCredentials: true,
        baseURL: `${BACKEND_URI}/`,
      });
  } catch (err) {
    error("Something went wrong");
  }
};

export const handleResponse = (res) => {
  try {
    const data = res?.data;

    if (res?.data?.error) {
      const error = data?.message ? data?.message : data.error;
      return Promise.reject(error);
    }
    return data;
  } catch (error) {
    error("Something went wrong");
    return true;
  }
};

export const handleError = (err) => {
  try {
    if (err?.response?.status == undefined) {
      error("Something went wrong");
      return true;
    } else if (err.response.status == 401) {
      // localStorage.setItem("isLogin", false);
      // localStorage.setItem("accessToken", null);
      localStorage.removeItem("isLogin");
      localStorage.removeItem("accessToken");

      document.cookie = document.cookie = `token= ;SameSite=strict;max-age=0}`;
      if (window.location.pathname !== "/login") {
        window.location = "/login";
      }
    } else if (err?.response?.data?.success == false) {
      error(err?.response?.data?.message);
      return true;
    }
  } catch (err) {
    error("Something went wrong");
    return err?.response?.data;
  }
};
