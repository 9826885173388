import React, { useState } from "react";

import { resetPassword } from "services/adminServices";
import enhancer from "../../../enhancers/ResetPasswordEnhancer";
import { compose } from "redux";
import { connect } from "react-redux";
import NavigationActions from "redux/navigation/actions";
import { useNavigate, useParams } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import loaderImg from "../../../assets/images/icon/loader-1.svg";

const { success, error } = NavigationActions;

const ResetPassword = (props) => {
  const { success, error } = props;
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
  } = props;

  const token = useParams();
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    const { values, isValid, handleSubmit } = props;
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      var resetdata = {
        token: token?.id,
        password: values.newpassword,
        confirmPassword: values.newpassword,
      };
      setLoading(true);
      await resetPassword(resetdata)
        .then((data) => {
          if (data?.success) {
            setPasswordResetSuccess(true);
            success(data?.message);
          } else {
            error(data?.message);
            setLoading(false);
          }
          setLoading(false);
        })
        .catch((e) => {
          error("Network error");
          setLoading(false);
        });
    }
  };

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid login-container">
      <div className="form-container login-form-container">
        <div className="login-icon"></div>

        {passwordResetSuccess ? (
          <>
            <div className="login-title">
              Your password has been successfully reset.
            </div>

            <div className=" pa-24">
              <button
                type="submit"
                className="btn form-button siteenterbutton"
                onClick={() => navigate("/login")}
              >
                Back to Login
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="login-title">Set New Password</div>
            <form className="pa-24" onSubmit={handleResetPassword}>
              <div className="form-group">
                <label>
                  Password <span className="error-msg">*</span>
                </label>
                <div className="input-group mb-3">
                  <input
                    type={togglePassword ? "text" : "password"}
                    className="form-control react-form-input"
                    id="newpassword"
                    onChange={handleChange}
                    value={values.newpassword}
                    onBlur={handleBlur}
                    placeholder="Password"
                  />
                  <div className="input-group-append password-view-btn">
                    {togglePassword ? (
                      <VisibilityOff onClick={() => setTogglePassword(false)} />
                    ) : (
                      <Visibility
                        onClick={() => {
                          setTogglePassword(true);
                        }}
                      />
                    )}
                  </div>
                </div>
                <Error field="newpassword" />
              </div>
              <div className="form-group">
                <label>
                  Confirm Password <span className="error-msg">*</span>
                </label>
                <div className="input-group mb-3">
                  <input
                    type={toggleConfirmPassword ? "text" : "password"}
                    className="form-control react-form-input"
                    id="confirmpassword"
                    onChange={handleChange}
                    value={values.confirmpassword}
                    onBlur={handleBlur}
                    placeholder="Confirm Password"
                  />
                  <div className="input-group-append password-view-btn">
                    {toggleConfirmPassword ? (
                      <VisibilityOff
                        onClick={() => setToggleConfirmPassword(false)}
                      />
                    ) : (
                      <Visibility
                        onClick={() => {
                          setToggleConfirmPassword(true);
                        }}
                      />
                    )}
                  </div>
                </div>
                <Error field="confirmpassword" />
              </div>
              <button type="submit" className="btn form-button siteenterbutton">
                {loading ? (
                  <img
                    src={loaderImg}
                    width="20px"
                    height="20px"
                    alt="loading"
                  />
                ) : (
                  " Reset Password"
                )}
              </button>
              <div className="text-center mt-10">
                <Link to="/login">Back to Login</Link>
              </div>
            </form>
          </>
        )}
      </div>
      {/* {loading && <Loader />} */}
    </div>
  );
};

export default compose(
  enhancer,
  connect(null, { success, error })
)(ResetPassword);
