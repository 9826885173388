import { api, handleResponse, handleError } from "./apiServices";

export const getAllEnquiry = (token, data) =>
  api(token)
    .get(
      `/api/inquiry?page=${data.currentPage}&limit=${data.limit}&search=${data.search}`
    )
    .then(handleResponse)
    .catch(handleError);

export const editInquiry = (token, id, data) =>
  api(token)
    .put(`/api/inquiry/${id}`, data)
    .then(handleResponse)
    .catch(handleError);

export const inquiryStatusUpdate = (token, id) =>
  api(token)
    .put(`/api/inquiry/status-change/${id}`)
    .then(handleResponse)
    .catch(handleError);

export const addInquiry = (data) =>
  api()
    .post(`/api/inquiry`, data)
    .then(handleResponse)
    .catch(handleError);

export const getInterestOfArea = () =>
  api()
    .get(`/api/area-of-interest`)
    .then(handleResponse)
    .catch(handleError);

export const deleteInquiry = (token, id) =>
  api(token)
    .delete(`/api/inquiry/${id}`)
    .then(handleResponse)
    .catch(handleError);
