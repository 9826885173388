import React, { useState } from "react";
import NavigationActions from "redux/navigation/actions";
import enhancer from "../../../enhancers/ForgotPasswordEnhancer";
import { compose } from "redux";
import { connect } from "react-redux";
import { forgotPassword } from "services/adminServices";
import { Link } from "react-router-dom";
import loaderImg from "../../../assets/images/icon/loader-1.svg";

const { success, error, fetching } = NavigationActions;

const ForgotPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    success,
    error,
    fetching,
    isValid,
    handleSubmit,
  } = props;

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      fetching();
      setLoading(true);
      await forgotPassword(values)
        .then((data) => {
          if (data?.success) {
            success(data?.message);
            setLoading(false);
          } else {
            error(data?.message);
            setLoading(false);
          }
        })
        .catch((e) => {
          error("Network error");
          setLoading(false);
        });
    }
  };

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid login-container">
      <div className="form-container login-form-container">
        <div className="login-icon"></div>
        <div className="login-title">Forgot Password ?</div>
        <div className="text-center form-info-text plr-24 mt-16">
          Provide your e-mail address to reset your password
        </div>
        <form className="pa-24" onSubmit={handleForgotPassword}>
          <div className="form-group">
            <input
              type="email"
              className="form-control react-form-input"
              id="email"
              aria-describedby="emailHelp"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="Enter email"
            />
            <Error field="email" />
          </div>
          <button type="submit" className="btn form-button siteenterbutton">
            {loading ? (
              <img src={loaderImg} width="20px" height="20px" alt="loading" />
            ) : (
              " Get Link"
            )}
          </button>
          <div className="text-center mt-10">
            <Link to="/login">Back to Login</Link>
          </div>
        </form>
      </div>
      {/* {loading && <Loader />} */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default compose(
  enhancer,
  connect(mapStateToProps, { success, error, fetching })
)(ForgotPassword);
