import { api, handleResponse, handleError } from "./apiServices";

export const forgotPassword = (data) =>
  api()
    .post("/api/forgot-password", data)
    .then(handleResponse)
    .catch(handleError);

export const resetPassword = (data) =>
  api()
    .post(`/api/reset-password`, data)
    .then(handleResponse)
    .catch(handleError);
