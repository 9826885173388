import { api, handleResponse, handleError } from "./apiServices";

export const getTermsAndCond = () =>
  api()
    .get(`/api/cms/terms_condition`)
    .then(handleResponse)
    .catch(handleError);

export const updateTermsAndCond = (token, data) =>
  api(token)
    .put(`/api/cms/terms_condition`, data)
    .then(handleResponse)
    .catch(handleError);
