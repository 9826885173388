import auth from "./auth/reducer";
import services from "./services/reducer";
import navigation from "./navigation/reducer";
import themeChanger from "./themeChanger/reducer";
import themeSetting from "./themeSettings/reducer";
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import dashboardNotification from "./dashboard/reducer";
const createReducer = asyncReducers =>
  combineReducers({
    auth,
    services,
    dashboardNotification,
    navigation,
    themeChanger,
    themeSetting,
    router: routerReducer,

    ...asyncReducers
  });

export default createReducer;
