import { api, handleResponse, handleError } from "./apiServices";

export const loginApi = (data) =>
  api()
    .post("/api/login", data)
    .then(handleResponse)
    .catch(handleError);

export const logoutApi = (token) =>
  api(token)  
    .post("/api/logout")
    .then(handleResponse)
    .catch(handleError);

export const getAdminProfile = (token) =>
  api(token)
    .get("api/user-profile")
    .then(handleResponse)
    .catch(handleError);

export const changePasswordMerchant = (token, data) =>
  api(token)
    .post("/api/change-password", data)
    .then(handleResponse)
    .catch(handleError);

export const editProfile = (token, data) =>
  api(token)
    .put("/api/user-profile", data)
    .then(handleResponse)
    .catch(handleError);
