import React, { useEffect, useState } from "react";
import HeaderWrapper from "./header.style";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { people1, people2, people3, ProfileLockScreen } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import PopoverBlock from "./PopoverBlock";
import { useNavigate } from "react-router-dom";

const { logout, setUser } = AuthActions;

const Header = (props) => {
  const { notificationData, drawerMiniMethod, mini, token } = props;
  // const [Notification, setNotification] = useState();
  let navigate = useNavigate();
  const userSignout = () => {
    props.setUser({});
    props.logout(token);
    // navigate("/login")
  };

  // useEffect(() => {
  //   setNotification(notificationData);
  // }, []);

  return (
    <HeaderWrapper {...props}>
      <div className="headerBack">
        <div className="flex-x align-center">
          <div className="drawer-handle-arrow">
            {mini ? (
              <button
                className="top-header-icon"
                onClick={() => drawerMiniMethod()}
              >
                <i className="fas fa-bars"></i>
              </button>
            ) : (
              <button
                className="top-header-icon"
                onClick={() => drawerMiniMethod()}
              >
                <i className="fas fa-bars"></i>
              </button>
            )}
          </div>
          <div
            className="mini-drawer-menu-icon"
            onClick={() => drawerMiniMethod()}
          >
            <i className="fas fa-bars" />{" "}
            <span className="app-name fs-16 bold-text">
              {"Webbrain Teach "}
            </span>
          </div>
          <div className="pl-10"></div>
          <div className="pl-10 flex-1"></div>
          <div className="pl-10">
            {/* <button id="notification" className="top-header-icon">
              <i className="far fa-bell"></i>
              <div className="button-badge fs-11 demi-bold-text">
                {" "}
                {Notification ? Notification.notificationCount : 0}
              </div>
            </button> */}
            {/* <UncontrolledPopover
              placement="bottom-start"
              target="notification"
              className="header-popover"
              trigger="focus"
            >
              <PopoverBody className="mail-popover-body">
                <div className="fs-13 bold-text mb-10">
                  You have {Notification?.notificationCount} Notifications.
                </div>
                <PopoverBlock
                  people={people1}
                  name="Juli Hacks"
                  text="Send You a message..."
                  created="Just Now"
                />
                <PopoverBlock
                  people={people2}
                  name="Scarlet JohnSon"
                  text="Like your status..."
                  created="22nd July 2019"
                />
                <PopoverBlock
                  people={people3}
                  name="Andrew Hales"
                  text="Tagged you in his status"
                  created="20th Jun 2019"
                />
              </PopoverBody>
            </UncontrolledPopover> */}
          </div>
          <div className="pl-10">
            <div id="profile">
              <img
                className="top-header-profile-class"
                src={ProfileLockScreen}
                alt="notify"
              />
            </div>
            <UncontrolledPopover
              className="roy-menu"
              innerClassName="roy-inner-content"
              placement="bottom-end"
              target="profile"
              trigger="legacy"
            >
              <PopoverBody>
                <div
                  className="roy-menu-list"
                  onClick={() => navigate("/profile")}
                >
                  My Profile
                </div>
                {/* <div className="roy-menu-list">Settings</div> */}
                <div className="roy-menu-list" onClick={userSignout}>
                  Logout
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken?.token,
    // notificationData: state.dashboardNotification.notification,
    user: state.auth.user,
  };
};

export default compose(connect(mapStateToProps, { logout, setUser }))(Header);
