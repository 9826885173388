import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal } from "reactstrap";
import enhancer from "../../../enhancers/InquiryEnhancer";
import { compose } from "redux";
import { useEffect } from "react";
import RoleActions from "redux/services/action";
import loaderImg from "../../../assets/images/icon/loader-1.svg";
import { iconDemo } from "helper/constant";
import { addInquiry, getInterestOfArea } from "services/inquiryServices";
import { getCourses } from "services/courseServices";
import moment from "moment";

const { success, error, fetching } = RoleActions;
const AddInquiry = (props) => {
  const {
    isEdit,
    editData,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    isValid,
    setValues,
    handleSubmit,
  } = props;

  const [course, setCourse] = useState([]);
  const [interestOfArea, setInterestOfArea] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    isEdit && setValues({ ...editData });
  }, [editData]);

  useEffect(() => {
    // getCourses("all")
    //   .then((res) => {
    //     if (res?.data) {
    //       setCourse(res?.data?.items);
    //     }
    //   })
    //   .catch((e) => {
    //     error(e.message);
    //   });
    getInterestOfArea()
      .then((res) => {
        if (res?.data) {
          setInterestOfArea(res?.data?.items);
        }
      })
      .catch((e) => {
        error(e.message);
      });
  }, []);

  const AddInquiryHandler = async (e) => {
    values.phoneNumber = String(values?.phoneNumber);
    setValues(values);
    e.preventDefault();
    handleSubmit();
    if (!isValid) {
      return;
    }

    const data = {
      name: values?.name,
      address: values?.address,
      email: values?.email,
      phoneNumber: values?.phoneNumber,
      // courseId: values?.courseId,
      areaOfInterest: values?.areaOfInterest,
      reference: values?.reference,
      currentlyAt: values?.currentlyAt ?? "",
      currentlyWorkingAs: values?.currentlyWorkingAs ?? "",
      // joiningDate: values?.joiningDate,
    };
    if (data?.reference === "friend") {
      data.friendName = values?.friendName;
      data.friendWorkingArea = values?.friendWorkingArea;
    }

    setLoading(true);
    await addInquiry(data)
      .then((res) => {
        if (res?.success) {
          success(res?.message);
          setLoading(false);
          setIsOpen(true);
        } else {
          error(res?.message);
          setLoading(false);
        }
      })
      .catch((e) => {
        error("Network error");
        setLoading(false);
      });
  };

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="table-body">
      <div
        className="d-flex justify-content-between align-items-center flex-wrap pb-3"
        style={{ gap: "10px" }}
      >
        <div className="logo">
          <img alt="web" src={iconDemo} />
        </div>
        <div
          className="d-flex align-items-center"
          style={{ maxWidth: "450px" }}
        >
          {/* <h4>
            <b>Address:</b>
          </h4> */}
          <div className="address-text">
            {process.env.REACT_APP_COMPANY_ADDRESS}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center comn-title">
        Inquiry Form
      </div>
      <form>
        <div className="form-group">
          <label>
            Full Name{" "}
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            placeholder="Full Name"
          />
          <Error field="name" />
        </div>

        <div className="form-group">
          <label>
            Address <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address}
            placeholder="Address"
          />
          <Error field="address" />
        </div>

        <div className="form-group">
          <label>
            Email <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            placeholder="Email"
          />
          <Error field="email" />
        </div>

        <div className="form-group">
          <label>
            Phone Number
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          </label>
          <input
            type="number"
            className="form-control react-form-input"
            id="phoneNumber"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phoneNumber}
            placeholder="Phone Number"
          />
          <Error field="phoneNumber" />
        </div>
        <div className="form-group">
          <label>
            college/University/Company{" "}
            {/* <span style={{ color: "red", fontWeight: "bold" }}>*</span> */}
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="currentlyWorkingAs"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.currentlyWorkingAs}
            placeholder="college/University/Company"
          />
          <Error field="currentlyWorkingAs" />
        </div>
        <div className="form-group">
          <label>
            Current Semester/Current Company{" "}
            {/* <span style={{ color: "red", fontWeight: "bold" }}>*</span> */}
          </label>
          <input
            type="text"
            className="form-control react-form-input"
            id="currentlyAt"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.currentlyAt}
            placeholder="Current Semester/Current Company"
          />
          <Error field="currentlyAt" />
        </div>
        {/* <div className="form-group">
          <label>
            Course ID{" "}
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          </label>
          <select
            name="courseId"
            value={values.courseId}
            onChange={handleChange}
            onBlur={handleBlur}
            className="form-control react-form-input"
          >
            {course.map(({ _id, name }) => (
              <option key={_id} value={_id}>
                {name.charAt(0).toUpperCase() + name.slice(1)}{" "}
              </option>
            ))}
          </select>
          <Error field="courseId" />
        </div> */}
        {/* <div className="form-group">
          <label>
            Joining Date{" "}
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>
          </label>
          <input
            type="date"
            className="form-control react-form-input"
            id="joiningDate"
            onChange={handleChange}
            onBlur={handleBlur}
            value={moment(values.joiningDate).format("YYYY-MM-DD")}
            // placeholder="date"
            // defaultValue={values.date}
          />
          <Error field="joiningDate" />
        </div> */}
        <div className="form-group">
          <div
            className="d-flex justify-content-center align-items-center py-3"
            style={{ fontSize: "36px", fontWeight: "bolder" }}
          >
            Course of Interest
          </div>
          <div className="d-flex" style={{ flexWrap: "wrap" }}>
            {interestOfArea?.map(({ name }) => (
              <div className="col-md-4">
                <label key={name}>
                  <input
                    type="checkbox"
                    name="areaOfInterest"
                    value={name}
                    checked={values?.areaOfInterest?.includes(name)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />{" "}
                  {name}
                </label>
              </div>
            ))}
          </div>
          <Error field="areaOfInterest" />
        </div>

        <div className="form-group">
          <div
            className="d-flex justify-content-center align-items-center py-3"
            style={{ fontSize: "36px", fontWeight: "bolder" }}
          >
            Reference
          </div>
          <div className="d-flex" style={{ flexWrap: "wrap" }}>
            {[
              "other",
              "friend",
              "website",
              "instagram",
              "linkedin",
              "facebook",
            ].map((value) => (
              <div className="col-md-2">
                <label key={value}>
                  <input
                    type="radio"
                    name="reference"
                    value={value}
                    checked={values.reference === value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />{" "}
                  {value.charAt(0).toUpperCase() + value.slice(1)}
                </label>
              </div>
            ))}
          </div>
          <Error field="reference" />
        </div>
        {values.reference === "friend" && (
          <>
            <div className="form-group">
              <label>
                Friend's Name{" "}
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              </label>
              <input
                type="text"
                name="friendName"
                className="form-control react-form-input"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.friendName}
                placeholder="Friend's Name"
              />
              <Error field="friendName" />
            </div>
            <div className="form-group">
              <label>
                Friend's Working Area{" "}
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              </label>
              <input
                type="text"
                name="friendWorkingArea"
                className="form-control react-form-input"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.friendWorkingArea}
                placeholder="Friend's Working Area"
              />
              <Error field="friendWorkingArea" />
            </div>
          </>
        )}
        <div className="row justify-content-center">
          <button
            color="success"
            onClick={(e) => AddInquiryHandler(e)}
            type="submit"
            className="btn chargeButton my-3 btnsize"
          >
            {loading ? (
              <img src={loaderImg} width="20px" height="20px" alt="loading" />
            ) : (
              "Submit"
            )}{" "}
          </button>
        </div>
      </form>
      <Modal isOpen={isOpen} backdrop={true} size="sm">
        <>
          <div
            className="p-3 d-flex justify-content-center align-items-center"
            style={{ fontSize: "22px", fontWeight: "700" }}
          >
            Thank You...!
          </div>

          <div className="d-flex justify-content-center pa-24">
            <button
              type="submit"
              style={{
                height: "40px",
                borderRadius: "40px",
                color: "white",
                fontSize: "16px",
                width: "30%",
              }}
              className="btn btn-primary form-button"
              onClick={() => {
                setIsOpen(false);
                window.location.reload();
              }}
            >
              Ok
            </button>
          </div>
        </>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken?.token,
  };
};

export default compose(
  enhancer,
  connect(mapStateToProps, { success, error, fetching })
)(AddInquiry);
