import React, { useState } from "react";
import { connect } from "react-redux";
import Layout from "layouts/DashboardLayout.jsx";
import { Route, Routes, Navigate } from "react-router-dom";

import { LockScreen, Login, ForgotPassword } from "../views/pages/index";
import { compose } from "redux";
import authActions from "redux/auth/actions";
import navigationAction from "redux/navigation/actions";
import Loader from "components/Loader";
import ResetPassword from "views/pages/authentication/ResetPassword";
import StudentEnrollment from "views/pages/publicLink/StudentEnrollment";
import StudentInquiry from "views/pages/publicLink/StudentInquiry";

const { check, logout, setUser } = authActions;
const { success, error, getNotificationData } = navigationAction;

const Dashboard = React.lazy(() =>
  import("../views/pages/dashboards/Dashboard")
);

const Profile = React.lazy(() =>
  import("../views/pages/authentication/Profile")
);

const Course = React.lazy(() => import("../views/pages/course/Courselist"));
const Inquiry = React.lazy(() => import("../views/pages/inquiry/Inquirylist"));

const StudentEnrollments = React.lazy(() =>
  import("../views/pages/enrollment/Enrollmentlist")
);
const TermsAndCond = React.lazy(() =>
  import("../views/pages/termsandcondition/TermsAndCond")
);

const ReactRoutes = (props) => {
  const { check, user_id, token } = props;

  const [isLoading, setIsLoading] = useState(true);

  return !isLoading ? (
    <Loader />
  ) : (
    <>
      <Routes>
        <Route path="/" element={token ? <Layout /> : <Navigate to="login" />}>
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loader />}>
                <Dashboard />
              </React.Suspense>
            }
          />

          <Route
            path="dashboard"
            element={
              <React.Suspense fallback={<Loader />}>
                <Dashboard />
              </React.Suspense>
            }
          />

          <Route
            path="profile"
            element={
              <React.Suspense fallback={<Loader />}>
                <Profile />
              </React.Suspense>
            }
          />

          <Route
            path="/courses"
            element={
              <React.Suspense fallback={<Loader />}>
                <Course />
              </React.Suspense>
            }
          />
          <Route
            path="/student-inquires"
            element={
              <React.Suspense fallback={<Loader />}>
                <Inquiry />
              </React.Suspense>
            }
          />

          <Route
            path="/student-enrollments"
            element={
              <React.Suspense fallback={<Loader />}>
                <StudentEnrollments />
              </React.Suspense>
            }
          />

          <Route
            path="/terms-condition"
            element={
              <React.Suspense fallback={<Loader />}>
                <TermsAndCond />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path="/login"
          element={token ? <Navigate to="/Dashboard" /> : <Login />}
        />
        <Route path="/student-enrollment" element={<StudentEnrollment />} />
        <Route path="/student-inquiry" element={<StudentInquiry />} />

        <Route
          path="/lockscreen"
          element={token ? <Navigate to="/Dashboard" /> : <LockScreen />}
        />
        <Route
          path="/forgotPassword"
          element={token ? <Navigate to="/Dashboard" /> : <ForgotPassword />}
        />
        <Route path="/reset-password/:id" element={<ResetPassword />} />
      </Routes>
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.accessToken?.token,
  user: state.auth.user,
  user_id: state.auth.user_id,
});

export default compose(
  connect(mapStateToProps, {
    check,
    logout,
    success,
    error,
    getNotificationData,
    setUser,
  })
)(ReactRoutes);
