import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .trim()
      .min(1, "Name must be at least 1 character")
      .max(50, "Name must be at most 50 characters")
      .required("Name is required"),
    currentAddress: Yup.string()
      .trim()
      .min(1, "Current address must be at least 1 character")
      .max(500, "Current address must be at most 500 characters")
      .required("Current address is required"),
    permanentAddress: Yup.string()
      .trim()
      .min(1, "Permanent address must be at least 1 character")
      .max(500, "Permanent address must be at most 500 characters")
      .required("Permanent address is required"),
    phoneNumber: Yup.string()
      .trim()
      .matches(
        /^[1-9]\d{9}$/,
        "Phone number must be exactly 10 digits and should not start with zero"
      )
      .required("Phone number is required"),
    email: Yup.string()
      .email("The email you have entered is incorrect")
      .max(60)
      .trim()
      .matches(/\./, " Please enter a valid email")
      .required("Please enter registered email"),
    alterNativePhoneNumber: Yup.string()
      .matches(
        /^[1-9]\d{9}$/,
        "Phone number must be exactly 10 digits and should not start with zero"
      )
      .nullable(),
    // candidateSign: Yup.string().required("Candidate sign is required"),
    authoritySign: Yup.string().nullable(),
    profilePicture: Yup.string().required("Please enter profile picture"),
    pancard: Yup.string().nullable(),
    aadharBack: Yup.string().required("Please enter aadhar card back image"),
    aadharFront: Yup.string().required("Please enter aadhar card front image"),
    companyAddress: Yup.string().nullable(),
    // startDate: Yup.date().required("Joining date is required"),
    currentlyWorking: Yup.string()
      .oneOf(
        ["company", "college", "university", "other"],
        "Invalid currently working value"
      )
      .required("Currently working is required"),
    currentlyWorkingPlace: Yup.string().required(
      "Currently working place is required"
    ),
    currentSem: Yup.string().nullable(),
    designation: Yup.string().nullable(),
    paymentMethod: Yup.string()
      .oneOf(["installment", "full_payment"], "Invalid payment method")
      .required("Payment method is required"),
    paymentMode: Yup.string()
      .oneOf(
        ["cash", "online_payment", "cheque", "other"],
        "Invalid payment method"
      )
      .nullable(),
    courseId: Yup.string().required("Course ID is required"),
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable()
  }),
  mapPropsToValues: () => ({
    name: "",
    currentAddress: "",
    permanentAddress: "",
    phoneNo: "",
    alternatePhoneNo: "",
    email: "",
    collegeUniversityCompany: "",
    semDesignation: "",
    course: "",
    paymentMethod: "",
    paymentMode: "",
    pancard: "",
    aadharFront: "",
    aadharBack: "",
    startDate: "",
    endDate: ""
  }),
  validateOnMount: true,
  handleSubmit: values => {
    // Handle form submission here
  },
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
