import React, { useState } from "react";
import { connect } from "react-redux";
import { loginBack } from "helper/constant";

const UserProfile = () => {
  const [feed, setFeed] = useState(true);
  const [media, setMedia] = useState(false);
  const [info, setInfo] = useState(false);

  const activePanel = (data) => {
    setFeed(false);
    setMedia(false);
    setInfo(false);
    if (data === "feed") {
      setFeed(true);
    } else if (data === "media") {
      setMedia(true);
    } else if (data === "info") {
      setInfo(true);
    }
  };

  const Back = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const titleStyle = {
    background: "#563c91",
    color: "white",
    fontWeight: 600,
  };
  const activeColor = {
    color: "#563c91",
  };

  const profileTabLink = {
    borderBottom: `2px solid`,
    borderColor: "#563c91",
  };

  return <div className="Profile-component">User Profile</div>;
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
  };
};

export default connect(mapStateToProps, null)(UserProfile);
