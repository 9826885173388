import { toast } from "react-toastify";
// import { unreadNotificationsCount } from "services/userNotificationServices";

const navigationAction = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  CLOSE: "CLOSE",
  FETCHING: "FETCHING",
  ONETIMEMODAL: "ONETIMEMODAL",
  TOGGLEMODAL: "TOGGLEMODAL",
  TOGGLETAB: "TOGGLETAB",
  GROUPSTATUSDATA: "GROUPSTATUSDATA",
  NOTIFICATIONDATA: "NOTIFICATIONDATA",
  SUBSCRIPTIONSUCCESS: "SUBSCRIPTIONSUCCESS",
  ONBOARDINGTOGGLEMODAL: "ONBOARDINGTOGGLEMODAL",
  FINISHSETUPDATA: "FINISHSETUPDATA",
  success: (messages) => {
    messages !== "" &&
      toast.success(messages, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    return {
      type: navigationAction.SUCCESS,
      resType: "success",
      message: messages,
      show: true,
      isFetching: false,
    };
  },
  error: (messages) => {
    messages !== "" &&
      toast.error(messages, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    return {
      type: navigationAction.ERROR,
      resType: "error",
      message: messages,
      show: true,
      isFetching: false,
    };
  },
  close: () => {
    return {
      type: navigationAction.CLOSE,
      show: false,
      isFetching: false,
    };
  },
  fetching: () => ({
    type: navigationAction.FETCHING,
    isFetching: true,
  }),

  toggleSubscriptionLoader: (value) => {
    return {
      type: navigationAction.SUBSCRIPTIONSUCCESS,
      isSubscriptionSuccess: value,
    };
  },

  toggleSubscribeModal: (value) => {
    return {
      type: navigationAction.TOGGLEMODAL,
      subscription: value,
    };
  },

  toggleOneTimeModal: (value) => {
    return {
      type: navigationAction.ONETIMEMODAL,
      oneTimeModal: value,
    };
  },

  // getNotificationData: (token) => async (dispatch) => {
  //   try {
  //     var notificationData = [];

  //     await unreadNotificationsCount(token).then((data) => {
  //       if (data?.success) {
  //         notificationData = data.data;
  //         return dispatch({
  //           type: navigationAction.NOTIFICATIONDATA,
  //           notificationData: notificationData,
  //         });
  //       } else {
  //         return dispatch({
  //           type: navigationAction.NOTIFICATIONDATA,
  //           notificationData: notificationData,
  //         });
  //       }
  //     });
  //   } catch (err) {
  //     return dispatch({
  //       type: navigationAction.NOTIFICATIONDATA,
  //       notificationData: notificationData,
  //     });
  //   }
  // },

  toggleONboardingDataModal: (value) => {
    return {
      type: navigationAction.ONBOARDINGTOGGLEMODAL,
      onboardingModal: value,
    };
  },
};

export default navigationAction;
