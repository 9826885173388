import React, { useState } from "react";
import { connect } from "react-redux";
import enhancer from "../../../enhancers/StudentEnrollmentEnhancer";
import { compose } from "redux";
import { useEffect } from "react";
import RoleActions from "redux/services/action";
import docIcon from "../../../assets/images/icon/profile.jpg";
import pancardIcon from "../../../assets/images/icon/pancard.jpg";
import loaderImg from "../../../assets/images/icon/loader-1.svg";

import aadharFrontIcon from "../../../assets/images/icon/aadharFront.png";
import aadharBackIcon from "../../../assets/images/icon/aadharback.jpg";

import { addEnrollment } from "services/enrollmentServices";
import { getCourses } from "services/courseServices";
import { iconDemo } from "helper/constant";
import { getTermsAndCond } from "services/termsAndCondService";
import { Modal } from "reactstrap";
import "./form-design.css";
import moment from "moment";

const { success, error, fetching } = RoleActions;
const AddEnrollmentData = props => {
  const {
    isEdit,
    editData,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    isValid,
    setValues,
    handleSubmit,
    setFieldValue
  } = props;

  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [pancardPreview, setPancardPreview] = useState(null);
  const [aadharFrontPreview, setAadharFrontPreview] = useState(null);
  const [aadharBackPreview, setAadharBackPreview] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState({});

  const [emiPrice, setEmiPrice] = useState(selectedCourse[0]?.emiPrice || []);

  const [termsAndCondition, setTermsAndCondition] = useState({
    name: "",
    description: ""
  });

  const url = process.env.REACT_APP_BACKEND_URI;
  useEffect(() => {
    isEdit && setValues({ ...editData });
    if (isEdit) {
      isImage(editData?.profilePicture)
        ? setImagePreview(`${url}/${editData?.profilePicture}`)
        : setImagePreview(docIcon);
      isImage(editData?.pancard)
        ? setPancardPreview(`${url}/${editData?.pancard}`)
        : setPancardPreview(pancardIcon);
      isImage(editData?.aadharFront)
        ? setAadharFrontPreview(`${url}/${editData?.aadharFront}`)
        : setAadharFrontPreview(aadharFrontIcon);
      isImage(editData?.aadharBack)
        ? setAadharBackPreview(`${url}/${editData?.aadharBack}`)
        : setAadharBackPreview(aadharBackIcon);
    }
  }, [editData]);

  useEffect(() => {
    getCourses("all")
      .then(res => {
        if (res?.data) {
          res?.data?.items.unshift({ _id: null, name: "Select course" });
          setCourse(res?.data?.items);
        }
      })
      .catch(e => {
        error(e.message);
      });
    getTermsAndCond()
      .then(res => {
        const { name, description } = res?.data;
        setTermsAndCondition({ name, description });
      })
      .catch(e => {
        error("Network error");
      });
  }, []);

  function isImage(url) {
    if (url) {
      const extension = url
        ?.split(".")
        ?.pop()
        ?.toLowerCase();
      return ["jpg", "jpeg", "png", "gif", "svg", "pdf"].includes(extension);
    } else {
      return false;
    }
  }

  const addEnrollmentDataHandler = async e => {
    values.phoneNumber = String(values.phoneNumber);
    values.alterNativePhoneNumber = String(
      values?.alterNativePhoneNumber ?? ""
    );
    setValues(values);
    e.preventDefault();
    handleSubmit();
    if (!isValid) {
      return;
    }

    const data = {
      name: values?.name || "",
      currentAddress: values?.currentAddress || "",
      permanentAddress: values?.permanentAddress || "",
      phoneNumber: values?.phoneNumber || "",
      email: values?.email || "",
      alterNativePhoneNumber: values?.alterNativePhoneNumber || "",
      candidateSign: values?.candidateSign || "",
      authoritySign: values?.authoritySign || "",
      profilePicture: values?.profilePicture || "",
      pancard: values?.pancard || "",
      aadharFront: values?.aadharFront || "",
      aadharBack: values?.aadharBack || "",
      companyAddress: process.env.REACT_APP_COMPANY_ADDRESS || "",
      currentlyWorking: values?.currentlyWorking || "",
      currentlyWorkingPlace: values?.currentlyWorkingPlace || "",
      currentSem: values?.currentSem || "",
      designation: values?.designation || "",
      paymentMethod: values?.paymentMethod || "",
      courseId: values?.courseId || "",
      startDate: values?.startDate || "",
      emiPrice: emiPrice ? JSON.stringify(emiPrice) : null
    };
    const formData = new FormData();

    // Append each key-value pair from the data object to the FormData object
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });

    setLoading(true);
    await addEnrollment(formData)
      .then(res => {
        if (res?.success) {
          success(res?.message);
          setIsOpen(true);
          setLoading(false);
        } else {
          error(res?.message);
          setLoading(false);
        }
      })
      .catch(e => {
        error("Network error");
        setLoading(false);
      });
    setLoading(false);
  };

  const handleInputChange = (e, index) => {
    const newEmiPrice = [...emiPrice]; // Create a copy of the state array
    newEmiPrice[index] = e.target.value; // Update the value at the specified index
    setEmiPrice(newEmiPrice); // Update the state with the new array
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const handleImageUpload = async (e, keyName) => {
    try {
      const file = e.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          // Check if the file is an image
          if (file.type.startsWith("image")) {
            keyName == "profilePicture" && setImagePreview(reader.result);
            keyName == "pancard" && setPancardPreview(reader.result);
            keyName == "aadharFront" && setAadharFrontPreview(reader.result);
            keyName == "aadharBack" && setAadharBackPreview(reader.result);
          } else {
            // Set a default image URL as the preview
            keyName == "profilePicture" && setImagePreview(docIcon);
            keyName == "pancard" && setPancardPreview(pancardIcon);
            keyName == "aadharFront" && setAadharFrontPreview(aadharFrontIcon);
            keyName == "aadharBack" && setAadharBackPreview(aadharBackIcon);
          }
          setFieldValue(keyName, file);
        };

        reader.readAsDataURL(file);
      }
    } catch (error) {}
  };
  return (
    <>
      <div className="table-body">
        <div
          className="d-flex justify-content-between align-items-center flex-wrap pb-3"
          style={{ gap: "10px" }}
        >
          <div className="logo">
            <img alt="web" src={iconDemo} />
          </div>
          <div
            className="d-flex align-items-center"
            style={{ maxWidth: "450px" }}
          >
            {/* <h4>
            <b>Address:</b>
          </h4> */}
            <div className="address-text">
              {process.env.REACT_APP_COMPANY_ADDRESS}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center comn-title">
          Student Enrollment Form
        </div>
        <form>
          <div className="form-group">
            <label>
              Full Name{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="Name"
            />
            <Error field="name" />
          </div>

          <div className="form-group">
            <label>
              Current Address{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="currentAddress"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.currentAddress}
              placeholder="Current Address"
            />
            <Error field="currentAddress" />
          </div>

          <div className="form-group">
            <label>
              Permanent Address{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="permanentAddress"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.permanentAddress}
              placeholder="Permanent Address"
            />
            <Error field="permanentAddress" />
          </div>

          <div className="form-group">
            <label>
              Phone Number
              <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <input
              type="number"
              className="form-control react-form-input"
              id="phoneNumber"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phoneNumber}
              placeholder="Phone Number"
            />
            <Error field="phoneNumber" />
          </div>

          <div className="form-group">
            <label>
              Email <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="Email"
            />
            <Error field="email" />
          </div>

          <div className="form-group">
            <label>Alternate Phone Number </label>
            <input
              type="number"
              className="form-control react-form-input"
              id="alterNativePhoneNumber"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.alterNativePhoneNumber}
              placeholder="Alternate Phone Number"
            />
            <Error field="alterNativePhoneNumber" />
          </div>
          <div className="form-group">
            <label>
              Joining Date{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <input
              type="date"
              className="form-control react-form-input"
              id="startDate"
              onChange={handleChange}
              onBlur={handleBlur}
              value={moment(values.startDate).format("YYYY-MM-DD")}
              min={moment().format("YYYY-MM-DD")} // Setting the min attribute to today's date
              // placeholder="date"
              // defaultValue={values.date}
            />
            <Error field="startDate" />
          </div>
          <div className="form-group">
            <label>
              Course <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <select
              name="courseId"
              value={values.courseId}
              onChange={e => {
                setFieldValue("courseId", e.target.value);
                setSelectedCourse(course.filter(d => d._id === e.target.value));
              }}
              onBlur={handleBlur}
              className="form-control react-form-input"
            >
              {course.map(({ _id, name }) => (
                <option key={_id} value={_id}>
                  {name.charAt(0).toUpperCase() + name.slice(1)}
                </option>
              ))}
            </select>
            <Error field="courseId" />
          </div>

          {/* <>
            {selectedCourse[0]?.isFreeTrial && values?.courseId && (
              <div className="free-course-text">
                Free demo classes {selectedCourse[0].freeTrialDays} days
              </div>
            )}
          </> */}
          <div className="form-group">
            <label>
              Profile Picture{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <input
              type="file"
              className="form-control react-form-input"
              id="profilePicture"
              onChange={e => {
                handleImageUpload(e, "profilePicture");
              }}
              onBlur={handleBlur}
              placeholder="Image"
              accept="image/*,"
            />
            <Error field="profilePicture" />
          </div>

          {imagePreview !== null ? (
            <div>
              <img
                src={imagePreview}
                style={{
                  width: "100px",
                  maxHeight: "100px",
                  objectFit: "contain",
                  marginBottom: "10px"
                }}
              />
            </div>
          ) : null}

          {/* <div className="form-group">
            <label>
              Pancard{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <input
              type="file"
              className="form-control react-form-input"
              id="pancard"
              onChange={(e) => {
                handleImageUpload(e, "pancard");
              }}
              onBlur={handleBlur}
              placeholder="Image"
              accept="image/*,"
            />
            <Error field="pancard" />
          </div>

          {pancardPreview !== null ? (
            <div>
              <img
                src={pancardPreview}
                style={{
                  width: "100px",
                  maxHeight: "100px",
                  objectFit: "contain",
                  marginBottom: "10px",
                }}
              />
            </div>
          ) : null} */}

          <div className="form-group">
            <label>
              Aadhar Card Front Image{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <input
              type="file"
              className="form-control react-form-input"
              id="aadharFront"
              onChange={e => {
                handleImageUpload(e, "aadharFront");
              }}
              onBlur={handleBlur}
              placeholder="Image"
              accept="image/*,"
            />
            <Error field="aadharFront" />
          </div>

          {aadharFrontPreview !== null ? (
            <div>
              <img
                src={aadharFrontPreview}
                style={{
                  width: "100px",
                  maxHeight: "100px",
                  objectFit: "contain",
                  marginBottom: "10px"
                }}
              />
            </div>
          ) : null}

          <div className="form-group">
            <label>
              Aadhar Card Back Image{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <input
              type="file"
              className="form-control react-form-input"
              id="aadharBack"
              onChange={e => {
                handleImageUpload(e, "aadharBack");
              }}
              onBlur={handleBlur}
              placeholder="Image"
              accept="image/*,"
            />
            <Error field="aadharBack" />
          </div>

          {aadharBackPreview !== null ? (
            <div>
              <img
                src={aadharBackPreview}
                style={{
                  width: "100px",
                  maxHeight: "100px",
                  objectFit: "contain",
                  marginBottom: "10px"
                }}
              />
            </div>
          ) : null}

          <div className="form-group">
            <label>
              Currently Working{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <div className="d-flex flex-wrap">
              {["company", "college", "university", "other"].map(
                (method, index) => (
                  <div key={index} className="mr-4 ml-4">
                    <input
                      type="radio"
                      name="currentlyWorking"
                      className="form-check-input"
                      id={`currentlyWorking${method}`}
                      onChange={() => setFieldValue("currentlyWorking", method)}
                      checked={values.currentlyWorking === method}
                    />
                    <label
                      htmlFor={`currentlyWorking${method}`}
                      className="form-check-label"
                    >
                      {(
                        method.charAt(0).toUpperCase() + method.slice(1)
                      ).replace("_", " ")}
                    </label>
                  </div>
                )
              )}
            </div>
            <Error field="currentlyWorking" />
          </div>
          <div className="form-group">
            <label>
              Payment Mode{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <div className="d-flex flex-wrap">
              {["cash", "online_payment", "cheque", "other"].map(
                (method, index) => (
                  <div key={index} className="mr-4 ml-4">
                    <input
                      type="radio"
                      name="paymentMode"
                      className="form-check-input"
                      id={`paymentMode${method}`}
                      onChange={() => setFieldValue("paymentMode", method)}
                      checked={values.paymentMode === method}
                    />
                    <label
                      htmlFor={`paymentMode${method}`}
                      className="form-check-label"
                    >
                      {(
                        method.charAt(0).toUpperCase() + method.slice(1)
                      ).replace("_", " ")}
                    </label>
                  </div>
                )
              )}
            </div>
            <Error field="paymentMode" />
          </div>
          <div className="form-group">
            <label>
              Currently Working Place{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="currentlyWorkingPlace"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.currentlyWorkingPlace}
              placeholder="Currently Working Place"
            />
            <Error field="currentlyWorkingPlace" />
          </div>
          {values.currentlyWorking === "college" ||
          values.currentlyWorking === "university" ? (
            <div className="form-group">
              <label>
                Current Sem{" "}
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control react-form-input"
                id="currentSem"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.currentSem}
                placeholder="Current Sem"
              />
              <Error field="currentSem" />
            </div>
          ) : values.currentlyWorking === "company" ? (
            <div className="form-group">
              <label>
                Designation{" "}
                <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control react-form-input"
                id="designation"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.designation}
                placeholder="Designation"
              />
              <Error field="designation" />
            </div>
          ) : null}

          <div className="form-group">
            <label>
              Payment Method{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </label>
            <div className="d-flex flex-wrap">
              <div className="mr-4 ml-4">
                <input
                  type="radio"
                  name="paymentMethod"
                  className="form-check-input"
                  id="paymentMethod"
                  onChange={() => setFieldValue("paymentMethod", "installment")}
                  checked={values.paymentMethod === "installment"}
                />
                <label htmlFor="paymentMethod" className="form-check-label">
                  Installment
                </label>
              </div>

              <div className="ml-2">
                <input
                  type="radio"
                  name="paymentMethod"
                  className="form-check-input"
                  id="paymentMethod"
                  onChange={() =>
                    setFieldValue("paymentMethod", "full_payment")
                  }
                  checked={values.paymentMethod === "full_payment"}
                />
                <label htmlFor="paymentMethod" className="form-check-label">
                  Full Payment
                </label>
              </div>
            </div>
            <Error field="paymentMethod" />
          </div>

          {values.paymentMethod === "installment" && values.courseId ? (
            <>
              {Array.isArray(selectedCourse[0]?.emiPrice) &&
                selectedCourse[0]?.emiPrice.map((e, index) => (
                  <div className="form-group" key={index}>
                    <label>Installment {index + 1}</label>
                    <input
                      type="number"
                      name={`installment${index + 1}`}
                      className="form-control react-form-input"
                      onChange={e => handleInputChange(e, index)}
                      onBlur={handleBlur}
                      value={emiPrice[index] || ""} // Use emiPrice state for value
                    />
                  </div>
                ))}

              {/* <Error field="friendName" /> */}
              {/* </div>
              <div className="form-group">
                <label>Installment Second </label>
                <input
                  type="text"
                  name="installment2"
                  className="form-control react-form-input"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={selectedCourse[0]?.emiPrice}
                  disabled
                />
              </div> */}
            </>
          ) : values.paymentMethod === "full_payment" && values.courseId ? (
            <>
              <div className="form-group">
                <label>Amount </label>
                <input
                  type="number"
                  name="amount"
                  className="form-control react-form-input"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={selectedCourse[0]?.price}
                  disabled
                />
              </div>
            </>
          ) : null}

          <div className="row justify-content-center">
            <button
              color="success"
              onClick={e => addEnrollmentDataHandler(e)}
              type="submit"
              className="btn chargeButton my-3  btnsize"
            >
              {loading ? (
                <img src={loaderImg} width="20px" height="20px" alt="loading" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>

        <div className="d-flex flex-column pt-3">
          <b>{termsAndCondition.name}:&nbsp; </b>{" "}
          <div
            dangerouslySetInnerHTML={{ __html: termsAndCondition.description }}
          />
        </div>
      </div>
      <Modal isOpen={isOpen} backdrop={true} size="m">
        <>
          <div
            className="p-3 d-flex justify-content-center align-items-center"
            style={{ fontSize: "22px", fontWeight: "700" }}
          >
            Thank you for enrolling! You will receive all updates via email. If
            you have any queries, please contact us at{" "}
            {process.env.REACT_APP_CONTACT_NO} or email us at{" "}
            {process.env.REACT_APP_CONTACT_EMAIL}.
          </div>

          <div className="d-flex justify-content-center pa-24">
            <button
              type="submit"
              style={{
                height: "40px",
                borderRadius: "40px",
                color: "white",
                fontSize: "16px",
                width: "30%"
              }}
              className="btn btn-primary form-button"
              onClick={() => {
                setIsOpen(false);
                window.location.reload();
              }}
            >
              Ok
            </button>
          </div>
        </>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken?.token
  };
};

export default compose(
  enhancer,
  connect(mapStateToProps, { success, error, fetching })
)(AddEnrollmentData);
