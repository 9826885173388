import React, { useState } from "react";
import { iconDemo } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";
import NavigationActions from "redux/navigation/actions";
import AuthActions from "redux/auth/actions";
import { loginApi } from "services/authServices";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import enhancer from "../../../enhancers/LoginFormEnhancer";
import loaderImg from "../../../assets/images/icon/loader-1.svg";

const { login, setUser } = AuthActions;

const {
  success,
  error,
  toggleOneTimeModal,
  fetching,
  toggleSubscriptionLoader,
} = NavigationActions;

const Login = (props) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    submitCount,
    isValid,
  } = props;

  let navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    handleSubmit();

    if (!isValid) {
      return;
    }

    fetching();
    setLoading(true);
    await loginApi(values)
      .then((data) => {
        if (data?.success) {
          success(data?.message);
          props.login(data?.data);
          props.setUser(data?.data);
          navigate("/dashboard");
          setLoading(false);
        } else {
          error(data?.message);
          setLoading(false);
        }
      })
      .catch((e) => {
        error("Network error");
        setLoading(false);
      });
  };

  const loginContainer = {
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
  };

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid login-container" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={iconDemo} alt="icon" height="100px" />
        </div>
        <div className="login-title">Sign in to your account</div>
        <form className="pa-24">
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control react-form-input"
              id="email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur}
              placeholder="Email"
            />
            {<Error field="email" />}
          </div>

          <div className="form-group">
            <label>Password</label>
            <div className="input-group mb-3">
              <input
                type={togglePassword ? "text" : "password"}
                className="form-control react-form-input"
                id="password"
                onChange={handleChange}
                value={values.password}
                onBlur={handleBlur}
                placeholder="Password"
              />
              <div className="input-group-append password-view-btn">
                {togglePassword ? (
                  <VisibilityOff
                    style={{ fontSize: "20px" }}
                    onClick={() => setTogglePassword(false)}
                  />
                ) : (
                  <Visibility
                    style={{ fontSize: "20px" }}
                    onClick={() => {
                      setTogglePassword(true);
                    }}
                  />
                )}
              </div>
            </div>

            {<Error field="password" />}
          </div>

          <button
            style={{
              height: "50px",
              borderRadius: "50px",
              color: "white",
              fontSize: "16px",
            }}
            type="submit"
            className="btn btn-primary form-button"
            onClick={(e) => handleLogin(e)}
          >
            {loading ? (
              <img src={loaderImg} width="20px" height="20px" alt="loading" />
            ) : (
              "Login"
            )}
          </button>
          <div
            className="text-center link-label"
            onClick={() => navigate("/forgotPassword")}
          >
            Forgot Password ?
          </div>
        </form>
      </div>
      {/* {loading && <Loader />} */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken?.token,
  };
};

export default compose(
  enhancer,
  connect(mapStateToProps, {
    login,
    success,
    error,
    toggleOneTimeModal,
    toggleSubscriptionLoader,
    fetching,
    setUser,
  })
)(Login);
