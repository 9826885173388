import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("Name is required"),
    address: Yup.string()
      .trim()
      .required("Address is required"),
    email: Yup.string()
      .email("The email you have entered is incorrect")
      .max(60)
      .trim()
      .matches(/\./, " Please enter a valid email")
      .required("Please enter registered email"),
    phoneNumber: Yup.string()
      .trim()
      .matches(
        /^[1-9]\d{9}$/,
        "Phone number must be exactly 10 digits and should not start with zero"
      )
      .required("Phone number is required"),
    // courseId: Yup.string()
    //   .trim()
    //   .required("Course is required"),
    areaOfInterest: Yup.array()
      .of(Yup.string().trim())
      .required("Area of interest is required"),
    // joiningDate: Yup.date().required("Joining date is required"),
    reference: Yup.string()
      .trim()
      .oneOf([
        "friend",
        "website",
        "instagram",
        "linkedin",
        "facebook",
        "other",
      ])
      .required("Reference is required"),
    friendName: Yup.string().trim(),
    //   .when("reference", {
    //     is: "friend",
    //     then: Yup.string()
    //       .trim()
    //       .required("Name is required"),
    //   }),
    friendWorkingArea: Yup.string().trim(),
    //   .when("reference", {
    //     is: "friend",
    //     then: Yup.string()
    //       .trim()
    //       .required("Name is required"),
    //   }),
    currentlyAt: Yup.string()
      .trim()
      .nullable(),
    currentlyWorkingAs: Yup.string()
      .trim()
      .nullable(),
  }),
  mapPropsToValues: (props) => ({
    name: "",
    address: "",
    email: "",
    phoneNumber: "",
    reference: "",
    courseId: "",
    currentlyAt: "",
    currentlyWorkingAs: "",
    friendWorkingArea: "",
    friendName: "",
    areaOfInterest: "",
  }),
  validateOnMount: true,
  handleSubmit: (values) => {
    // Handle form submission here
  },
  displayName: "CustomValidationForm",
  enableReinitialize: true,
});

export default formikEnhancer;
